import React from 'react';
import Layout from '../components/Layout';

const About = () => (
  <Layout>
    <h2 className="title">schotter & staub</h2>
    <div>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam ad ab ex iusto repellendus inventore minima distinctio delectus, nulla corporis, eligendi. Alias minima nobis aut, id molestiae exercitationem iure vitae.</p>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam ad ab ex iusto repellendus inventore minima distinctio delectus, nulla corporis, eligendi. Alias minima nobis aut, id molestiae exercitationem iure vitae.</p>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam ad ab ex iusto repellendus inventore minima distinctio delectus, nulla corporis, eligendi. Alias minima nobis aut, id molestiae exercitationem iure vitae.</p>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam ad ab ex iusto repellendus inventore minima distinctio delectus, nulla corporis, eligendi. Alias minima nobis aut, id molestiae exercitationem iure vitae.</p>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam ad ab ex iusto repellendus inventore minima distinctio delectus, nulla corporis, eligendi. Alias minima nobis aut, id molestiae exercitationem iure vitae.</p>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam ad ab ex iusto repellendus inventore minima distinctio delectus, nulla corporis, eligendi. Alias minima nobis aut, id molestiae exercitationem iure vitae.</p>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam ad ab ex iusto repellendus inventore minima distinctio delectus, nulla corporis, eligendi. Alias minima nobis aut, id molestiae exercitationem iure vitae.</p>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam ad ab ex iusto repellendus inventore minima distinctio delectus, nulla corporis, eligendi. Alias minima nobis aut, id molestiae exercitationem iure vitae.</p>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam ad ab ex iusto repellendus inventore minima distinctio delectus, nulla corporis, eligendi. Alias minima nobis aut, id molestiae exercitationem iure vitae.</p>
      <p>Hahah</p>
    </div>
  </Layout>
);

export default About;
